/**
 * Configuration Class
 * Responsible for All The Api Configuration for the Application
 */
import { getFromStorage } from "../utils/localStorage";
class Configuration {
  //  static BASE_URL = process.env.REACT_APP_BASE_URL
  static MAINTENANCE = process.env.REACT_APP_MAINTENANCE;
  static google0Auth=process.env.REACT_APP_GOOGLE_OAUTH_KEY || ''
  static stripeKey=process.env.REACT_APP_STRIPE_KEY
  static facebookId=process.env.REACT_APP_FACEBOOK_ID
  static General_EndPoints = {
    home: "/",
  };

  // end points url
  static endPoints = {
    carBrands: "/api/car-brands?",
    getCategories: "/api/categories?",
    signIn: "/api/auth/login",
    me: "/api/auth/me",
    signUp: "/api/auth/register",
    forgotPassword: "/api/auth/password-reset",
    otp: "/api/otp/send",
    logout: "/api/auth/logout",
    regions: "/api/regions?",
    getAllCart: "/api/cart",
    addToCart: "/api/cart",
    deleteCartItem: "/api/cart",
    flushCart: "/api/cart/flush",
    refreshToken: "/api/auth/refresh-token",
    tempOrder: "/api/order",
    confirmOrder: "api/order/confirm",
    availableSchedules: "/api/order/available-schedules",
    switchSelectedCar: "/api/profile/switch-car",
    applyCoupon: "/api/order/apply-coupon",
    myOrders: "/api/profile/orders",
    onlinePaymentInent:"/api/order/transaction",
    socialLogin:'/api/auth/social-login',
    profileCar:"/api/profile/car",
    announcment:'/api/announcements',
    redeemPoints: "/api/order/redeem-points",
    addAdress:'/api/profile/address',
    blogs:'api/blogs',
    blogItem:'/api/blogs'


  };

  static statusCode = {
    success: 200,
    error: 422,
    generalError: 500,
    refreshToken: 401,
    firstTimeSocialLogin:401
  };

  static DefaultDirection = "ltr";

  /**
   * Function To Check if the user is Authenticated or not
   * @returns {boolean}
   */
  //locastorage
  static isAuth = () => {
    const token = getFromStorage("@fixxPressUser")?.accessToken;
    const isUserLoggedIn = token && token !== "" ? true : false;
    return isUserLoggedIn;
  };

  static  getDocumentDirection = () => {
    return document.dir;
  }
  // to do
  static isIOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };
  static Regions: any;
}

export default Configuration;
